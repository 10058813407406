/**
 * @extends Config
 */

(function($){
    "use strict";
    console.log('Patient Payments.Paid Boot');

    /**
     *
     * @param {App} App
     */
    let viewModel = function(App) {
        let self = this;

        this.requestSent = false;

        this.$idField = null;
        this.$form = null;

        this.$emailsModal = $('#send-invoice');
        /**
         * Init
         */
        this.init = function () {
            console.log('Patient Payments.Paid Init');
            let $main = $('#trips-paid');

            self.$emailsModal.on('hidden.bs.modal', function(){
                if(!self.requestSent) return;
                App.showLoader();
            });

            ko.applyBindings(this, $main[0]);
        };

        /**
         * Send emails
         * @returns {boolean}
         */
        this.koSendEmails = function(form){
            let $form = $(form);
            if(this.requestSent) return false;
            this.requestSent = true;

            this.$emailsModal.modal('hide');

            axios.post($form.attr('action'), $form.serialize())
                .then(function (response) {
                    let success = 0;
                    if(typeof(response.data.result)!=='undefined') success = response.data.result;
                    if(success) App.messages.success('Emails sent');
                    else {
                        if(_.isNil(response.data.error)){
                            App.messages.error('Emails wasn\'t sent');
                        }
                        else{
                            App.messages.error(response.data.error);
                        }
                    }

                    // console.log('Response:');
                    // console.log(response);
                })
                .catch(function (response) {
                    App.messages.error('Request failed: ' + response.statusText);
                    console.log('Request failed with error: ' + response.statusText);
                })
                .then(function(){
                    self.requestSent = false;
                    App.hideLoader();
                });
            return false;
        };

    };

    App.registerModule(viewModel, 'payment/result$');

})(jQuery);