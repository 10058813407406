(function($){
    "use strict";
    console.log('Top Navbar Boot');

    let viewModel = function(App) {
        let self = this;

        this.$logoutForm = null;
        this.$leftElements = [];
        this.$rightElements = [];
        /**
         * Init
         */
        this.init = function () {
            console.log('Top Navbar Init');

            let $navbar = $('#navbar-top');

            this.$logoutForm = $('#logout-form');
            this.$changeCompanyForm = $('#change-company-form');
            this.$changeCompanyForm.find('select').find('option:selected');
            if(this.$changeCompanyForm.data('id')){
                this.$changeCompanyForm.find('select > option[value="'+this.$changeCompanyForm.data('id')+'"]').prop('selected', true);
            }
            this.$leftElements = $('#navbar-left').find("li");
            this.$rightElements = $('#navbar-right').find("li");

            this.$leftElements.each(this.processNavLinks);
            this.$rightElements.each(this.processNavLinks);

            if($navbar.length) ko.applyBindings(this, $navbar[0]);
        };

        /**
         * Process all nav links in nav bar. Set active to matched links. Insert loader on click.
         * @param {int} i Element index
         * @param {node} el Nav bar element <li><a></li>
         */
        this.processNavLinks = function(i, el){
            let $el = $(el);
            if($el.data('url') !== undefined){
                let preg = $el.data('url').replace('/','\/');
                if(Config.route.match(preg)!==null){
                    $el.addClass('active');
                }
            }
            if($el.hasClass('dropdown')){
                $el.find('ul.dropdown-menu').find('a').click(function(){
                    App.showLoader();
                });
            }
            else $el.find('a').click(function(){
                App.showLoader();
            });
        };

        this.koLogout = function(){
            this.$logoutForm.submit();
            App.showLoader();
            return false;
        };

        this.koChangeCompany = function(){
            this.$changeCompanyForm.submit();
            App.showLoader();
            return true;
        };

    };

    App.registerModule(viewModel, 'common-navbar-top');

})(jQuery);