/**
 */

(function($){
    "use strict";
    console.log('Patient Trips Boot');

    /**
     *
     * @param {App} App
     */
    let viewModel = function(App) {
        let self = this;

        this.koFormLid = ko.observable('');
        this.$form = null;
        this.dataTable = null;

        /**
         * Init
         */
        this.init = function () {
            console.log('Patient Trips Init');
            let $main = $('#trips');

            this.$form = $main.find('form');
            this.koTotal = ko.observable('$'+App.formatMoney(0));
            this.total = 0;
            this.koAllowSubmit = ko.observable(0);

            this.$rows = this.$form.find('.trips-row').each(function(idx, el){
                let $el = $(el);
                $el.click(function(){
                    let $checkbox = $el.find('.cell1 input');
                    let new_status = !$checkbox.is(':checked');
                    $checkbox.prop('checked', new_status);
                    $el.toggleClass('selected', new_status);
                    if(new_status) {
                        self.koAllowSubmit(self.koAllowSubmit()+1);
                        self.total += parseFloat($el.data('cost'));
                    }
                    else {
                        self.koAllowSubmit(self.koAllowSubmit()-1);
                        self.total -= parseFloat($el.data('cost'));
                    }
                    self.koTotal('$' + App.formatMoney(self.total));
                });
            });

            this.dataTable = $('#trips-table').DataTable({
                dom: 'lBfrtip',
                paging: false,
                // scrollY: '200px',
                buttons: [
                    {
                        text: 'Select All',
                        action: function ( e, dt, node, config ) {
                            self.$rows.each(function(idx, el){
                                let $el = $(el);
                                let $checkbox = $el.find('.cell1 input');
                                $checkbox.prop('checked', true);
                                $el.addClass('selected');
                                self.koAllowSubmit(self.koAllowSubmit()+1);
                                self.total = 0;
                                self.$rows.each(function(idx, el){
                                    let $el = $(el);
                                    self.total += parseFloat($el.data('cost'));
                                });
                                self.koTotal('$' + App.formatMoney(self.total));
                            });
                        }
                    },
                    {
                        text: 'Select None',
                        action: function ( e, dt, node, config ) {
                            self.$rows.each(function(idx, el){
                                let $el = $(el);
                                let $checkbox = $el.find('.cell1 input');
                                $checkbox.prop('checked', false);
                                $el.removeClass('selected');
                                self.koAllowSubmit(self.koAllowSubmit()-1);
                                self.total = 0;
                                self.koTotal('$' + App.formatMoney(self.total));
                            });
                        }
                    }
                ],
                columnDefs: [ {
                    targets: 0,
                    render( data, type, row, meta ){
                        let result = data;
                        switch(type){
                            case 'filter':{
                                result = '';
                                if(data.indexOf('Paid')!==-1) result = 'paid';
                                break;
                            }
                            case 'sort':{
                                result = '';
                                if(data.indexOf('Paid')!==-1) result = 'paid';
                                break;
                            }
                        }
                        return result;
                    },
                    order: [ 0, 'asc' ]
                } ]
            });


            ko.applyBindings(this, $main[0]);
        };

    };

    App.registerModule(viewModel, 'trips$');

})(jQuery);
