/**
 * Specific scripts for admins area
 */
window.area = 'patients';
require('./bootstrap');
require('./app');
require('./common/messages');
require('./common/navbar-top');

require('./patients/trips');
require('./patients/trips-pay');
require('./patients/trips-paid');
require('./patients/schedule-edit');

window.jQuery(function(){
    window.App.init(window.Config.route);
});
