(function($){
    "use strict";
    console.log('Patient Trip Payment Boot');

    /**
     *
     * @param {App} App
     */
    let viewModel = function(App) {
        let self = this;

        this.koCheckRecordType = null;
        this.koPaymentMethod = null;
        this.$form = null;
        this.$paymentSelector = null;
        this.fullName = null;
        this.trips = window['trips_to_pay'];
        this.convenience_fee = window['convenience_fee'];

        /**
         * Init
         */
        this.init = function () {
            console.log('Patient Trip Payment Init');
            let $main = $('#trip-pay');

            this.$form = $main.find('form');
            this.$paymentSelector = $('#payment-selector');
            $('#zip').mask('99999');
            $('#payment-number').mask('9999-9999-9999-999?9',{
                placeholder: "****-****-****-****",
            });
            // $('#check-number').mask('99999999999999999');
            // $('#check-account').mask('99999999999999999');

            this.fullName = {
                firstname: ko.observable($('#first-name').val()),
                lastname: ko.observable($('#last-name').val())
            };
            this.koCheckRecordType = ko.observable('');
            this.koPaymentMethod = $('#ach-toggle').hasClass('active') ? ko.observable('check') : ko.observable('card');
            if ($('#ach-toggle').hasClass('active')){
                $("label[for='payment-name']").html('Name On Account<sup>*</sup>');
                $('#card-address-row').hide();
            }

            _.each(this.trips, function (el) {
                el.pay_amount = ko.observable(el.pay_amount);
            });

            this.total = ko.computed(function() {
                var amount = 0.0;
                _.each(self.trips, function(el){
                    amount += parseFloat(el.pay_amount());
                });
                return amount;
            });

            this.convenience_fee_amount = ko.computed(function() {
                return (self.total() * self.convenience_fee) / 100;
            });

            this.total_with_fee = ko.computed(function() {
                return self.total() + self.convenience_fee_amount();
            });

            this.initFormValidation();

            this.$paymentSelector.find('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
                $('#'+ $(e.target).attr('aria-controls')).find('input:first').focus();
                //e.target // newly activated tab
                //e.relatedTarget // previous active tab
            });

            ko.applyBindings(this, $main[0]);
        };

        /**
         * Initialize form validation
         */
        this.initFormValidation = function(){
            let options = {
                rules: {
                    'payment[number]':{
                        required: true,
                        creditcard: true
                    }
                },
                submitHandler: function(form) {
                    App.showLoader();
                    form.submit();
                },
                invalidHandler: function(event, validator) {
                    let errors = validator.numberOfInvalids();
                    if (errors) {
                        App.messages.error('Please fill required fields');
                    }
                    else {
                        App.messages.error('');
                    }
                },
                errorPlacement : function(error, element) {
                    // Dummy
                }

            };
            this.$form.validate(options);
        };

        this.koSetPaymentCard = function(){
            self.koPaymentMethod('card');
            $('#card-address-row').show();
            $("label[for='payment-name']").html('Name On Card<sup>*</sup>');
        };
        this.koSetPaymentCheck = function(){
            self.koPaymentMethod('check');
            $('#card-address-row').hide();
            $("label[for='payment-name']").html('Name On Account<sup>*</sup>');
        };
        this.checkMaxPerPayment = function(){
            if($('#payment_range').val() > (this.total_with_fee() / $('#schedule_amount').val())){
                $('#payment_range').val(parseFloat(this.total_with_fee() / $('#schedule_amount').val()).toFixed(2));
            }
        }
        this.updateMaxPayments = function (maxPayments){
            const interval = $('#schedule_increment').val();
            let schedule_amount = $('#schedule_amount');
            switch (interval) {
                case 'weekly':
                    maxPayments = maxPayments * 4;
                    break;
                case 'biweekly':
                    maxPayments = maxPayments * 2;
                    break;
            }
            schedule_amount.html('');
            //schedule_amount.html('<option value="">Please Select</option>');
            for (let i=2;i<=maxPayments;i++){
                schedule_amount.append(`<option value="${i}">${i}</option>`);
            }
        }
    };
    App.registerModule(viewModel, 'trips/pay');

})(jQuery);
