/**
 * Global APP object
 */

import * as _ from "lodash";

(function($){
    "use strict";
    console.log(window.area + ' App Boot');

    let modules = [];
    let commons = {
        modules: []
    };

    /**
     * App object for Companies
     * @global App
     * @namespace App
     * @constructor
     */
    let App = function(){

        let self = this;

        /**
         * Modal window for loader
         * @type {jQuery}
         */
        this.$loader = null;
        /**
         * Page loader
         * @type {null|jQuery}
         */
        this.$loaderPanel = null;
        /**
         * Messages module
         * @type {Messages}
         */
        this.messages = null;
        /**
         * Module for current page
         * @type {object}
         */
        this.currentModule = null;
        /**
         * Footer object
         * @type {jQuery}
         */
        this.$footer = null;
        /**
         * Body object
         * @type {jQuery}
         */
        this.$body = null;

        this.init = function(route){
            console.log(window.area + ' App Init');

            this.$loader = $('#loader');
            this.$loaderPanel = $('#loader-panel');
            this.$footer = $('footer');
            this.footerStatus = 'absolute';
            this.footerObserver = setInterval(function(){
                self.footerControl();
            }, 500);
            this.$body = $('body');

            this.$loader.modal({
                backdrop: 'static',
                keyboard: false,
                show: false
            });

            try{
                // Initialize all common modules
                _.each(commons.modules, function(module){
                    module.init();
                });
            }
            catch (e){
                console.error('Error while common module was initialized');
                console.error(e.message);
            }

            try{
                /**
                 * Shortcut to messages module
                 * @type {{info: function(string), success: function(string), error: function(string)}}
                 */
                this.messages = this.getCommonModule('messages').messages;

                // Initialize appropriate module
                _.each(modules, function(module){
                    if(window.Config.route.search(module.path)!==-1) {
                        if(self.currentModule!==null){
                            console.error('Modules conflict:');
                            console.error(self.currentModule);
                            console.error(module.path);
                        }
                        self.currentModule = new module.model(self);
                        return false;
                    }
                    return true;
                });
                if(this.currentModule!==null) this.currentModule.init();
            }
            catch(e){
                console.error('Error while page module was initialized');
                console.error(e.message);
            }

            try{
                /**
                 * Hide Loader on browser back
                 * @param event
                 */
                window.onpageshow = function(event) {
                    if (event.persisted) {
                        setTimeout(function(){
                            window.App.hideLoader();
                        }, 1000)
                    }
                };

                /**
                 * Enable loader for buttons
                 */
                $('[data-toggle=loader]').click(function(){
                    self.showLoader();
                });
                $('form.submit-show-loader').submit(function(){
                    self.showLoader();
                });
            }
            catch(e){
                console.error('Error detected');
                console.error(e.message);
            }

            this.$loaderPanel.hide();
        };

        /**
         * Register Module
         * @param {Function} model
         * @param {string|void|regex} [path] Module path
         */
        this.registerModule =  function(model, path){
            if(typeof(path)==='undefined'){
                commons.push(new model(this));
            }
            else{
                if(path.indexOf('common-')===0) {
                    let module = new model(this);
                    commons.modules.push(module);
                    commons[path] = module;
                }
                else{
                    modules.push({
                        'path': path,
                        'model': model
                    });
                }
            }
        };

        /**
         * Show loader
         */
        this.showLoader = function(){
            this.$loader.modal('show');
        };

        /**
         * Hide loader
         */
        this.hideLoader = function () {
            this.$loader.modal('hide');
        };

        /**
         * Get common module
         * @param {string} moduleName
         * @returns {Object|null}
         */
        this.getCommonModule = function(moduleName){
            if(typeof(commons['common-'+moduleName]) !== 'undefined') return commons['common-'+moduleName];
            return null;
        };

        /**
         * Format to money x,xxx.xx
         * @param {number} money
         * @returns {string}
         */
        this.formatMoney = function(money){
            return money.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
        };

        this.footerControl = function(){
            if(this.$footer.height()+this.$body.height()>$(window).height()){
                if(this.footerStatus!=='relative'){
                    this.$footer.css({position: 'relative'});
                    this.footerStatus = 'relative';
                    console.log('Footer status changed to '+this.footerStatus);
                }
            }
            else{
                if(this.footerStatus!=='absolute'){
                    this.$footer.css({position: 'absolute'});
                    this.footerStatus = 'absolute';
                    console.log('Footer status changed to '+this.footerStatus);
                }
            }
        };
    };

    window.App = new App();

})(jQuery);
