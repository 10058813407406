(function($){
    "use strict";
    console.log('Messages Boot');

    /**
     * @memberOf App
     * @param {App} App
     * @namespace Messages
     */
    let viewModel = function(App) {
        let self = this;

        this.messages = {};

        /**
         * Init
         */
        this.init = function () {
            console.log('Messages Init');

            let $messages = $('#messages');
            console.log('Initialize messages with values: ');
            console.log(window.Config);
            this.messages.info = ko.observable(window.Config['message']);
            this.messages.success = ko.observable(window.Config['successMessage']);
            this.messages.error = ko.observable(window.Config['errorMessage']);

            this.koDisplay = ko.computed(function(){
                return self.messages.info() || self.messages.success() || self.messages.error();
            });

            ko.applyBindings(this, $messages[0]);
        };

    };

    App.registerModule(viewModel, 'common-messages');

})(jQuery);