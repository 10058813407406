(function($){
    "use strict";
    console.log('Schedule Boot');

    /**
     *
     * @param {App} App
     */
    let viewModel = function(App) {
        let self = this;
        this.$form = null;
        /**
         * Init
         */
        this.init = function () {
            let $main = $('#schedule');
            this.$form = $main.find('form');
            ko.applyBindings(this, $main[0]);
        };

        this.edit_btn = $('#edit_btn');
        this.save_btn = $('#save_btn');
        this.cancel_btn = $('#cancel_btn');
        this.delete_btn = $('#delete_btn');

        this.cancelClicked = function() {
            this.edit_btn.show();
            this.delete_btn.show();
            this.save_btn.hide();
            this.cancel_btn.hide();
            $('.toggleable').prop( "disabled", true );
        }

        this.editClicked = function() {
            this.edit_btn.hide();
            this.delete_btn.hide();
            this.save_btn.show();
            this.cancel_btn.show();
            $('.toggleable').prop( "disabled", false );
        }
    };
    App.registerModule(viewModel, 'schedule/{id}');

})(jQuery);
